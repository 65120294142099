import { render, staticRenderFns } from "./field-admin-view.html?vue&type=template&id=25a59347&scoped=true&external"
import script from "./field-admin-view.js?vue&type=script&lang=js&external"
export * from "./field-admin-view.js?vue&type=script&lang=js&external"
import style0 from "./field-admin-view.scss?vue&type=style&index=0&id=25a59347&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a59347",
  null
  
)

export default component.exports